<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup class="card-sdw">
            <CCard color="dark" text-color="white" class="p-4 p-1 text-center card-checkin" body-wrapper>
              <CCardBody>
                <CForm>
                  <div v-if="eventData === 'Not found'" class="pt-7">
                    <CRow class="pt-2">
                      <CCol class="text-center">
                        <CButton color="primary" class="py-3 px-4" @click="$router.push({ path: '/student/profile' })">กรอก Pin Code เพื่อ Check-in</CButton>
                      </CCol>
                    </CRow>
                    <div class="pt-4">
                      <h6>ไม่พบรายชื่อในการสอบ</h6>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="!eventDetail" class="pt-7">
                      <div v-if="!checkedIn" class="pt-5 pb-4">
                        <CButton color="outline-light" size="lg" class="px-5 py-3" v-on:click="studentCheckIn()"> <CIcon :height="100" name="cil-task" />&nbsp;Check-In </CButton>
                        <div class="pt-2">
                          <h6>กระบวนวิชา</h6>
                        </div>
                        <div class="pt-2">
                          <h3>
                            <b>{{ eventData.eventName }}</b>
                          </h3>
                        </div>
                      </div>
                      <div v-if="checkedIn">
                        <div class="text-fade success-checkmark">
                          <div class="check-icon">
                            <span class="icon-line line-tip"></span>
                            <span class="icon-line line-long"></span>
                            <div class="icon-circle"></div>
                            <div class="icon-fix"></div>
                          </div>
                        </div>
                        <h2>Check-in success!</h2>
                        <h6>รายละเอียดจะแสดงในอีก {{ countDown }} วินาที...</h6>
                      </div>
                    </div>
                    <div v-if="eventDetail" class="pt-0 text-fade">
                      <h4><b>ลิงค์ที่เกี่ยวข้องการสอบ</b></h4>
                      <div class="pt-1">
                        <CButton color="zoomblue" size="lg" class="px-5 py-3" @click="zoomDetail"> <CIcon :height="100" name="cil-qr-code" />&nbsp;ZOOM Meeting </CButton>
                      </div>
                      <div v-if="!showNotAllowToStart">
                        <div class="pt-2">
                          <CButton color="pastelpurple2" size="lg" class="px-5 py-3" @click="newtab(eventData.examLink)"
                            >&nbsp;&nbsp;&nbsp;&nbsp;<CIcon :height="100" name="cil-external-link" />&nbsp;Exam Link&nbsp;&nbsp;&nbsp;</CButton
                          >
                        </div>
                        <div v-if="!checkOut" class="pt-2">
                          <CButton color="danger" size="lg" class="px-2 py-2" @click="afterClickCheckout()"> <CIcon :height="100" name="cil-arrow-left" />&nbsp;Check-out </CButton>
                        </div>
                      </div>
                      <div v-if="showNotAllowToStart">
                        <div class="pt-2">
                          <div class="text-warning">
                            <h5>เวลาเริ่มสอบคือ</h5>
                            <h6>{{ showDateTimeStartExam }}</h6>
                          </div>
                          <CButton color="pastelpurple2" size="lg" class="px-5 py-3" disabled> <CIcon :height="100" name="cil-external-link" />&nbsp;CMU Exam Link<br /> </CButton>
                        </div>
                        <div v-if="!checkOut" class="pt-2">
                          <CButton color="danger" size="lg" class="px-2 py-2" disabled> <CIcon :height="100" name="cil-arrow-left" />&nbsp;Check-out </CButton>
                        </div>
                      </div>
                      <div v-if="checkOut" class="pt-3 pb-4 text-green">
                        <h6><b>คุณ Check-out เรียบร้อยแล้ว</b></h6>
                      </div>
                      <div class="pt-4">
                        <h4><b>ข้อมูลการติดต่อกรณีฉุกเฉิน</b></h4>
                      </div>
                      <div class="pt-2">
                        <CRow>
                          <CCol sm="4">
                            <h6><b>Event:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{ eventData.eventName }}</h6>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol sm="4">
                            <h6><b>Email:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{ eventData.contact.email }}</h6>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol sm="4">
                            <h6><b>เบอร์ติดต่อ:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{ eventData.contact.phone }}</h6>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol sm="4">
                            <h6><b>อื่นๆ:</b></h6>
                          </CCol>
                          <CCol sm="8">
                            <h6>{{ eventData.contact.other }}</h6>
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </div>
                  <div class="pt-3 d-lg-none">
                    <hr />
                    <h4><b>ข้อมูลส่วนตัว</b></h4>
                    <h6>
                      <CIcon name="cil-user" />&nbsp;
                      {{ displayName }}
                    </h6>
                    <CRow class="py-3">
                      <CCol col="3" class="text-right"> tel: </CCol>
                      <CCol col="6" class="text-center">
                        {{ studentProfile.telephone }}
                      </CCol>
                      <CCol col="3" class="text-left">
                        <a @click="updateStudentProfileData('telephone')">
                          <i class="fas fa-pen-square"></i>
                        </a>
                      </CCol>
                    </CRow>
                    <CButton color="danger" size="sm" class="" @click="$router.push({ path: '/signout' })"> log out </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard class="text-center py-7 d-md-down-none card-checkin" body-wrapper>
              <CCardBody>
                <h4>คุณเข้าสู่ระบบในชื่อ</h4>
                <br />
                <div class="c-avatar c-avatar-xl bg-secondary">
                  <CIcon :height="42" name="cil-user" />
                </div>
                <div class="pt-3">
                  <h3 class="animated">
                    <b>{{ displayName }}</b>
                  </h3>
                  <CRow>
                    <CCol sm="3" class="text-right"> tel: </CCol>
                    <CCol sm="6" class="text-center">
                      {{ studentProfile.telephone }}
                    </CCol>
                    <CCol sm="3" class="text-left">
                      <a @click="updateStudentProfileData('telephone')">
                        <i class="fas fa-pen-square"></i>
                      </a>
                    </CCol>
                  </CRow>
                </div>
                <div class="pt-3">
                  <CButton color="outline-danger" size="sm" class="" @click="$router.push({ path: '/signout' })"> log out </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal title="ข้อกำหนดและเงื่อนไขในการใช้บริการ" :show.sync="policyModal">
      ทีมงานกำลังพัฒนาระบบการสอบมีประสิทธิภาพมากยิ่งขึ้น ทางทีมงานจึงขอนุญาตเก็บข้อมูลการใช้งานของท่าน รวมถึงยอมรับข้อผิดพลาดของระบบที่เกิดจากการใช้งาน โปรดอนุญาติหากท่านยินยอมในพัฒนาการใช้บริการ
      เราจะทำอย่างดีที่สุดเพื่อรักษาข้อมูลส่วนตัวของท่าน

      <template #footer>
        <CButton @click="afterAcceptPolicy()" color="success">Accept</CButton>
        <CButton @click="policyModal = false" color="secondary">Close</CButton>
      </template>
    </CModal>
    <CModal title="Check-out Confirm?" :show.sync="confirmModal">
      คุณต้องการยืนยันที่จะCheck-out
      <template #footer>
        <CButton @click="studentCheckOut(userEmail)" color="danger">Ok</CButton>
        <CButton @click="confirmModal = false" color="secondary">Cancel</CButton>
      </template>
    </CModal>
    <!-- Modal Not fount Event Component -->
    <CModal title="ไม่พบรายชื่อของท่าน" :show.sync="notFoundModal" :closeOnBackdrop="false">
      <template #header-wrapper><span></span></template>
      <div class="px-3 pb-4">
        <CRow class="pb-3">
          <CCol class="text-center">
            <i class="far fa-times-circle fa-7x"></i>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="text-center pb-3">
            <h5><b>ไม่พบรายชื่อ {{userEmail}} ในการสอบนี้ กรุณาติดต่ออาจารย์ผู้สอน</b></h5>
          </CCol>
        </CRow>
        <div v-if="eventData !== 'Not found'" class="px-4">
          <CRow>
            <CCol sm="4">
              <h6><b>Email:</b></h6>
            </CCol>
            <CCol sm="8">
              <h6>{{ eventData.contact.email }}</h6>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="4">
              <h6><b>เบอร์ติดต่อ:</b></h6>
            </CCol>
            <CCol sm="8">
              <h6>{{ eventData.contact.phone }}</h6>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="4">
              <h6><b>อื่นๆ:</b></h6>
            </CCol>
            <CCol sm="8">
              <h6>{{ eventData.contact.other }}</h6>
            </CCol>
          </CRow>
        </div>
        <CRow class="pt-2">
          <CCol class="text-center">
            <CButton color="primary" class="py-3" style="padding-left: 4.7rem; padding-right: 4.7rem" @click="$router.go($router.currentRoute)"><i class="fas fa-redo"></i>&nbsp;รีโหลดหน้านี้</CButton>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol class="text-center">
            <CButton color="outline-danger" class="py-3 px-6" @click="$router.push({ path: '/signout' })">ออกจากระบบ</CButton>
          </CCol>
        </CRow>
      </div>
      <template #footer-wrapper><span></span></template>
    </CModal>
    <CModal title="ไม่พบการสอบนี้" :show.sync="notFoundEventModal" :closeOnBackdrop="false">
      <template #header-wrapper><span></span></template>
      <div class="px-4 pb-4">
        <CRow class="pb-3">
          <CCol class="text-center">
            <i class="far fa-times-circle fa-7x"></i>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="text-center pb-3">
            <h4><b>ไม่มีการสอบนี้</b></h4>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol class="text-center">
            <CButton color="primary" class="py-3 px-5" @click="$router.push({ path: '/student/profile' })">&nbsp;<CIcon name="cil-dialpad" />&nbsp;ใช้ Pin Code แทน&nbsp;</CButton>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol class="text-center">
            <CButton color="outline-danger" class="py-3 px-6" @click="$router.push({ path: '/signout' })">ออกจากระบบ</CButton>
          </CCol>
        </CRow>
      </div>
      <template #footer-wrapper><span></span></template>
    </CModal>
    <!-- Modal Zoom -->
    <CModal title="Zoom Meeting" :show.sync="zoomModal" color="zoomblue">
      <div v-if="showZoomQr" class="text-center">
        <vue-qrcode :scale="7" :value="zoomLink" />
        <h3 style="color: tomato">ลงชื่อเข้าใช้ด้วยอีเมล @cmu.ac.th เท่านั้น</h3>
        <h5><b>Meeting id:</b> {{ eventData.zoomMeeting.meetingid }}</h5>
        <h5 v-if="false"><b>Password:</b> {{ eventData.zoomMeeting.meetingpassword }}</h5>
        <CButton color="zoomblue" size="lg" class="px-5 py-3" @click="newtab(`${zoomLink}`)"> Launch Zoom </CButton>
      </div>
      <h5 class="text-center" v-else>ไม่พบข้อมูล Zoom meeting ในการสอบนี้</h5>
      <template #footer-wrapper><span></span></template>
    </CModal>
    <CModal title="Allow Location" :show.sync="locationBlock" color="zoomblue">
      <div class="px-3 py-3 pb-3 text-center">
        <CRow>
          <CCol sm="4">
            <h5>การสอบของคุณคือ</h5>
          </CCol>
          <CCol sm="8">
            <h4>
              <b>{{ eventData["eventName"] }}</b>
            </h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <h5>คุณจำเป็นต้องอนุญาตให้เข้าถึงที่อยู่ของคุณ</h5>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <h5>กรุณา allow location ที่ browser ของคุณ</h5>
          </CCol>
        </CRow>
        <CRow class="py-2">
          <CCol>
            <h3 style="color: tomato">ไม่เช่นนั้นคุณจะไม่สามารถเข้าสอบได้</h3>
          </CCol>
        </CRow>
        <CRow class="py-2">
          <CCol>
            <img :src="image1" width="300" height="auto" />
          </CCol>
        </CRow>
        <CRow class="py-2">
          <CCol>
            <img :src="image2" width="300" height="auto" />
          </CCol>
        </CRow>
        <CRow class="py-2">
          <CCol>
            <h5>* แนะนำให้ allow ผ่าน google chrome</h5>
          </CCol>
        </CRow>
      </div>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import Swal from "sweetalert2"
import VueQrcode from "vue-qrcode"
import apiExamManager from "../../services/exam-manager-api-services"
import imageAllow1 from "../../assets/images/allow-map1.png"
import imageAllow2 from "../../assets/images/allow-map2.png"
export default {
  name: "CheckIn",
  components: {
    VueQrcode,
  },
  data() {
    return {
      userEmail: localStorage.getItem("email"),
      userData: [],
      displayName: localStorage.getItem("name"),
      seen: false,
      confirmModal: false,
      policyModal: true,
      notFoundModal: false,
      notFoundEventModal: false,
      acceptPolicy: false,
      eventDetail: false,
      checkOut: false,
      countDown: 3,
      eventName: "",
      eventData: "Not found",
      eventId: "",
      reportId: "",
      zoomModal: false,
      zoomLink: "No Data",
      showZoomQr: false,
      showDateTimeStartExam: "กำลังโหลด...",
      showNotAllowToStart: false,
      noLocation: true,
      requireLocation: false,
      canBeCheckIn: false,
      checkedIn: false,
      locationBlock: false,
      studentLocationData: {},
      studentProfile: {},
      image1: "",
      image2: "",
    }
  },
  async created() {
    this.image1 = imageAllow1
    this.image2 = imageAllow2
    document.title = "Student Check-in"
    if (this.$route.params.eventid) {
      this.eventId = this.$route.params.eventid
    }
    this.getEventByUserEmail(this.userEmail, this.eventId)
    this.checkStudentHaveNoProfileData()
  },
  mounted() {},
    watch: {
      policyModal(oldstatus, newstatus) {
        // Swal.fire(`${newstatus}`)
        if (newstatus) {
          this.checkLocationRequire(newstatus)
        }
      },
    },
  methods: {
    reloadPage() {
      window.location.reload()
    },
    async checkLocationRequire(policyStatus) {
      if (policyStatus) {
        if (this.requireLocation) {
          Swal.fire({
            text: "กรุณารอสักครู่...",
            allowOutsideClick: false,
          })
          Swal.showLoading()
          await this.getProfileStudent()
          await this.getLocationStudent()
          // Swal.close()
          if (!this.noLocation) {
            this.canBeCheckIn = true
          }
        } else {
          this.canBeCheckIn = true
        }
      }
    },
    async testCheckInStudent() {
      if (this.requireLocation) {
        await this.getProfileStudent()
        await this.getLocationStudent()
        if (!this.noLocation) {
          this.canBeCheckIn = true
        }
      } else {
        this.canBeCheckIn = true
      }
    },
    async getLocationStudent() {
      try {
        if (this.requireLocation) {
          // ! Old style
          // let permissions = await navigator.permissions.query({
          //   name: "geolocation",
          // })
          // console.log(`permissions `, permissions)
          // if (permissions["state"] === "granted" || permissions["state"] === "prompt") {
          //   const coordinates = await this.$getLocation({
          //     enableHighAccuracy: true,
          //   })
          //   let savedStudentLocation = await apiExamManager.saveStudentLocation(this.userEmail, this.eventId, coordinates["lat"], coordinates["lng"])
          //   if (savedStudentLocation["status"]) {
          //     this.studentLocationData = savedStudentLocation["data"]
          //     this.noLocation = false
          //     this.locationBlock = false
          //   }
          // } else if (permissions["state"] === "denied") {
          //   console.log(`กรุณา allow geo location`)
          //   this.locationBlock = true
          // } else {
          //   this.locationBlock = true
          // }
          // ! New style
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getLocationSuccess, this.getLocationError)
            console.log("get location")
          } else { 
            this.locationBlock = true
          }
        }
      } catch (error) {
        // this.noLocation = true
        this.noLocation = false
        console.log(`error-getLocationStudent-msg-:${error}`)
      }
    },
    async getLocationSuccess (position) {
      const coordinates = await this.$getLocation({
        enableHighAccuracy: true,
      })
      let savedStudentLocation = await apiExamManager.saveStudentLocation(this.userEmail, this.eventId, coordinates["lat"], coordinates["lng"])
      if (savedStudentLocation["status"]) {
        this.studentLocationData = savedStudentLocation["data"]
        this.noLocation = false
        this.canBeCheckIn = true
        this.locationBlock = false
        Swal.close()
      }
    },
    async getLocationError (error) {
      console.log(`กรุณา allow geo location`)
      Swal.close()
      this.locationBlock = true
    },
    async updateStudentProfileData(caseUpdate) {
      switch (caseUpdate) {
        case "telephone":
          Swal.fire({
            title: "กรอกเบอร์โทรศัพท์เพื่อให้อาจารย์ติดต่อกลับในกรณีฉุกเฉิน",
            html: "ใช้สำหรับกรณีที่อาจารย์ต้องการติดต่อนักศึกษา",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
              maxlength: 10,
            },
            showCancelButton: true,
            confirmButtonText: "Add",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: (phoneNumber) => {
              let updateDate = {
                telephone: phoneNumber,
              }
              return apiExamManager
                .saveStudentProfile(this.userEmail, updateDate)
                .then((response) => {
                  console.log(response)
                  if (!response.data) {
                    throw new Error("Fail")
                  }
                  return response
                })
                .catch((error) => {
                  Swal.showValidationMessage(`Request failed: ${error}`)
                })
            },
            // allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            console.log("Result: ", result)
            if (result.isConfirmed) {
              Swal.fire("success", "เพิ่มเบอร์โทรศัพท์เรียบร้อย", "success")
              this.getProfileStudent()
            }
          })
          break
        default:
          break
      }
    },
    async checkStudentHaveNoProfileData() {
      const resStudentProfile = await this.getProfileStudent()
      if (resStudentProfile["status"]) {
        if (resStudentProfile["data"]["telephone"] === "") {
          Swal.fire({
            title: "กรอกเบอร์โทรศัพท์เพื่อให้อาจารย์ติดต่อกลับในกรณีฉุกเฉิน",
            html: "ใช้สำหรับกรณีที่อาจารย์ต้องการติดต่อนักศึกษา",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
              maxlength: 10,
            },
            showCancelButton: true,
            confirmButtonText: "Add",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: (phoneNumber) => {
              let updateDate = {
                telephone: phoneNumber,
              }
              return apiExamManager
                .saveStudentProfile(this.userEmail, updateDate)
                .then((response) => {
                  console.log(response)
                  if (!response.data) {
                    throw new Error("Fail")
                  }
                  return response
                })
                .catch((error) => {
                  Swal.showValidationMessage(`Request failed: ${error}`)
                })
            },
            // allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            console.log("Result: ", result)
            if (result.isConfirmed) {
              Swal.fire("success", "เพิ่มเบอร์โทรศัพท์เรียบร้อย", "success")
              this.getProfileStudent()
            }
          })
        }
      }
    },
    async getProfileStudent() {
      try {
        let studentProfile = await apiExamManager.getStudentProfile(this.userEmail)
        console.log("studentProfile ", studentProfile)
        this.studentProfile = studentProfile["data"]
        return studentProfile
      } catch (error) {
        console.log(`error-getProfileStudent-msg-:${error.message}`)
      }
    },
    async checkIsCanBeCheckIn() {
      let timeNow = new Date()
      let nowTimeStamp = timeNow.getTime() / 1000
      let eventTimeStamp = this.eventData.checkinTime
      let eventTimeForShow = await this.timeThaiFormat(eventTimeStamp)
      await this.checkIsCanBeStartExam()
      if (eventTimeStamp > nowTimeStamp) {
        Swal.fire({
          title: "ยังไม่ถึงเวลาเช็คอิน",
          html: `
          <b>กระบวนวิชา </b><br>
          ${this.eventData.eventName}<br>
          <b>เวลาเช็คอิน</b><br>
          ${eventTimeForShow}`,
          allowOutsideClick: false,
          showConfirmButton: false,
        })
        return false
      } else {
        return true
      }
    },
    async checkIsCanBeStartExam() {
      let timeNow = new Date()
      let nowTimeStamp = timeNow.getTime() / 1000
      let eventStartTimeStamp = this.eventData.startAt
      let eventTimeForShow = await this.timeThaiFormat(eventStartTimeStamp)
      this.showDateTimeStartExam = eventTimeForShow
      if (eventStartTimeStamp > nowTimeStamp) {
        this.showNotAllowToStart = true
      } else {
        this.showNotAllowToStart = false
      }
    },
    async timeThaiFormat(timestamp) {
      let option = { year: "numeric", month: "long", day: "numeric", weekday: "long", hour: "numeric", minute: "numeric", second: "numeric" }
      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", option)
    },
    async afterClickCheckout() {
      let allFinishedExam = true
      this.confirmModal = true
      let checkInStatus = await apiExamManager.checkUserCheckInStatus(this.eventId, this.userEmail)
      if (checkInStatus) {
        console.log(`status-`, checkInStatus)
      }
      // if (!checkInStatus) {
      //   this.$toast.warning("คุณยังไม่ได้ส่งข้อสอบ", {
      //     position: "top",
      //     duration: 3000,
      //   })
      // } else {
      //   }
    },
    async studentCheckIn() {
      Swal.fire({
        text: "กรุณารอสักครู่...",
        allowOutsideClick: false,
      })
      Swal.showLoading()
      console.log("this.canBeCheckIn:",this.canBeCheckIn)
      if (this.canBeCheckIn) {
        let response = await apiExamManager.userCheckIn(this.userEmail, this.eventId)
        if (!response["state_checkin"]) {
          this.$toast.error("Check-in Fail please try again", {
            position: "top",
            duration: 1000,
          })
          setTimeout(() => this.$router.go(this.$router.currentRoute), 800)
        } else {
          this.checkedIn = true
          this.countDownTimer()
        }
      } else {
        // ! get location
        let studentProfile = this.studentProfile
        console.log(`check-in fail `, studentProfile)
        await this.getLocationStudent()
      }
      Swal.close()
    },
    async afterAcceptPolicy() {
      let checkInTime = await this.checkIsCanBeCheckIn()
      if (checkInTime) {
        this.policyModal = false
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.showEventDetail()
      }
    },
    showEventDetail() {
      console.log("Detail")
      this.eventDetail = true
    },
    newtab(url) {
      var win = window.open(url, "_blank")
      win.focus()
    },
    async checkInReport() {
      // let reportData = await apiExamManager.getReportWithEventId(this.eventId)
      // let accountName = this.userEmail.substring(0, this.userEmail.lastIndexOf("@"));
      // console.log(reportData)
      // if(reportData){
      //   this.reportId = reportData.data._id
      //   let studentProfile = await apiExamManager.getStudentProfile(accountName)
      //   if(studentProfile){
      //     let resCheckIn = await apiExamManager.userReportCheckIn(this.reportId, this.userEmail)
      //     console.log(resCheckIn)
      //   }
      // }
    },
    async checkOutReport() {
      // let reportData = await apiExamManager.getReportWithEventId(this.eventId)
      // let accountName = this.userEmail.substring(0, this.userEmail.lastIndexOf("@"))
      // if(reportData){
      //   this.reportId = reportData.data._id
      //   let studentProfile = await apiExamManager.getStudentProfile(accountName)
      //   if(studentProfile){
      //     let resCheckOut = await apiExamManager.userReportCheckOut(this.reportId, this.userEmail)
      //     console.log(resCheckOut)
      //   }
      // }
    },
    async getEventByUserEmail(email, eventid) {
      let responseData = await apiExamManager.getEventOfUser(email, eventid)
      console.log("getEvent:", responseData)
      if (responseData["studentEvent"]) {
        this.eventData = responseData["eventData"]
        if (responseData["studentEvent"]["state_checkin"]) {
          this.policyModal = false
          this.acceptPolicy = true
          this.eventDetail = true
        }
        if (responseData["studentEvent"]["state_checkout"]) {
          this.checkOut = true
        }
        this.requireLocation = this.eventData["location_enable"]
      } else {
        this.policyModal = false
        if (responseData["eventData"]) {
          this.notFoundModal = true
          this.eventData = responseData["eventData"]
        } else {
          this.notFoundEventModal = true
          this.eventData = "Not found"
        }
      }
      this.checkIsCanBeCheckIn()
    },
    async studentCheckOut(email) {
      console.log("email check-out", email)
      let responseData = await apiExamManager.userCheckOut(email, this.eventId)
      if (responseData) {
        await this.checkOutReport()
        this.$router.go(this.$router.currentRoute)
      }
      return responseData
    },
    zoomDetail() {
      console.log("zoomDetail:", this.eventData)
      if (this.eventData.zoomMeeting.meetingLink !== "") {
        this.zoomLink = this.eventData.zoomMeeting.meetingLink
        let zoompwd = new URL(this.zoomLink).searchParams.get("pwd")
        console.log("zoompwd:", zoompwd)
        console.log("zoomLink:", this.zoomLink)
        if (zoompwd !== null) {
          this.eventData.zoomMeeting.meetingpassword = zoompwd
          let newZoomId = this.eventData.zoomMeeting.meetingid.split("?")
          this.eventData.zoomMeeting.meetingid = newZoomId[0]
        }
        this.showZoomQr = true
      } else {
        this.showZoomQr = false
      }
      this.zoomModal = true
    },
  },
}
</script>
